import React from "react";
import "../index.css";
import Header from "../components/Header";
function Cookie() {

  return (
    <div>
      <Header />
      <section id="cookie" className="the-main-service text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-header">
                <h2>Cookie Policy</h2>
              </div>
              <div className="text text-left">
                <p>
                  <i>
                    Questa politica sui cookie è stata aggiornata l'ultima volta
                    il 12 settembre 2024 e si applica ai cittadini e ai
                    residenti permanenti legali dello Spazio Economico Europeo e
                    della Svizzera.
                  </i>
                </p>
                <h4>Cookie presenti</h4>
                <p>
                  Il sito http://animusoperandi.it{" "}
                  <b style={{ fontWeight: "600" }}>non</b> utilizza Cookies.
                </p>
                <h4>Dettagli contatti</h4>
                <p>
                  Per domande e/o commenti riguardo la Cookie Policy e questa
                  dichiarazione, per favore contattaci usando i seguenti dati di
                  contatto:
                  <br />
                  Catia Lettieri
                  <br />
                  Via Giuseppe Mazzini, 38 - 05021 Acquasparta TR Italia
                  <br />
                  Sito web: https://animusoperandi.it
                  <br />
                  E-mail: info@animusoperandi.it
                  <br />
                  Numero di telefono: +393513772245
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Cookie;