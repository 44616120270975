import React from "react";
import "../index.css";
import Header from "../components/Header";
function Policies() {
  return (
    <div>
      <Header />
      <section id="cookie" className="the-main-service text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-header">
                <h2>Informativa Privacy </h2>
              </div>
              <div className="text text-left">
                <p>
                  Questa dichiarazione sulla privacy è stata aggiornata l'ultima
                  volta il 12 settembre 2024 e si applica ai cittadini e ai
                  residenti permanenti legali dello Spazio Economico Europeo e
                  della Svizzera.
                </p>
                <p>
                  In questa dichiarazione sulla privacy, spieghiamo cosa
                  facciamo con i tuoi dati che otteniamo attraverso
                  https://animusoperandi.it. Ti consigliamo di leggere
                  attentamente la dichiarazione. Durante l'elaborazione
                  rispettiamo i requisiti della legislazione sulla privacy. Ciò
                  significa, tra le altre cose, che:
                </p>
                <ul type="square">
                    <li>
                      comunichiamo chiaramente il motivo per il quale
                      processiamo dati personali. Facciamo questo per mezzo di
                      questa dichiarazione sulla privacy;
                    </li>
                    <li>
                      miriamo a limitare la raccolta di dati personali solo ai
                      dati personali richiesti per scopi legittimi;
                    </li>
                    <li>
                      prima chiediamo il tuo esplicito consenso per processare i
                      tuoi dati personali nei casi che richiedono il tuo
                      consenso;
                    </li>
                    <li>
                      adottiamo misure di sicurezza appropriate per proteggere i
                      tuoi dati personali e lo richiediamo anche da parti che
                      elaborano dati personali per nostro conto;
                    </li>
                    <li>
                      rispettiamo il tuo diritto di accesso ai tuoi dati
                      personali o di averli corretti o cancellati, su tua
                      richiesta.
                    </li>
                </ul>
                <p>
                    Se hai domande o vuoi sapere esattamente quali dati
                    conserviamo, contattaci.
                </p>
                <h4>1. Scopo, dati e periodo di conservazione</h4>
                <p>
                    Possiamo raccogliere o ricevere informazioni personali per
                    una serie di scopi legati alle nostre operazioni commerciali
                    che possono includere quanto segue:
                </p>
                <ul type="none">
                      <li>
                        <h5>
                          1.1 Contatto - Tramite telefono, posta, email e/o
                          moduli web
                        </h5>
                        <p><b>Per questo scopo usiamo i seguenti dati:</b></p>
                          <ul type="square">
                            <li>Un nome e cognome</li>
                            <li>Un indirizzo email</li>
                            <li>Un numero di telefono</li>
                          </ul>
                        <p><b>La base con la quale processiamo i dati è:</b>
                          <br />
                          <a
                            href="https://cookiedatabase.org/legal-bases/#consent"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Su disposizione del consenso.
                          </a>
                          <br />
                          <b>Periodo di conservazione</b>
                          <br />
                          Alla cessazione del servizio, conserviamo questi dati
                          per il seguente periodo: 12 mesi.
                        </p>
                      </li>
                </ul>
                  
                <h4>2. Cookie</h4>
                <p>
                    Il nostro sito web usa cookie. Per ulteriori informazioni
                    riguardo i cookie, per favore fai riferimento alla nostra{" "}
                    <a href="/cookie">Cookie Policy</a>.
                </p>
                <h4>3. Pratiche di divulgazione</h4>
                <p>Comunichiamo informazioni personali se ci viene
                  richiesto dalla legge o da un ordine del tribunale, in
                  risposta a un'autorità incaricata dell'applicazione della
                  legge, nella misura consentita da altre disposizioni di legge,
                  per fornire informazioni o per un'indagine su una questione
                  relativa alla sicurezza pubblica.
                </p>
                <p>
                  Se il nostro sito Web o organizzazione viene rilevato, venduto
                  o coinvolto in una fusione o acquisizione, i tuoi dati
                  potrebbero essere divulgati ai nostri consulenti e a eventuali
                  potenziali acquirenti e saranno trasmessi ai nuovi
                  proprietari.
                </p>
                <h4>4. Sicurezza</h4>
                <p>
                  Ci impegniamo per la sicurezza dei dati personali. Prendiamo
                  adeguate misure di sicurezza per limitare l'abuso e l'accesso
                  non autorizzato ai dati personali. Ciò garantisce che solo le
                  persone necessarie abbiano accesso ai tuoi dati, che l'accesso
                  ai dati sia protetto e che le nostre misure di sicurezza
                  vengano regolarmente riviste.
                </p>
                <h4>5. Siti web di terze parti</h4>
                <p>
                  Questa dichiarazione sulla privacy non si applica ai siti web
                  di terzi collegati tramite link sul nostro sito web. Non
                  possiamo garantire che queste terze parti gestiscano i vostri
                  dati personali in modo affidabile e sicuro. Vi consigliamo di
                  leggere le dichiarazioni sulla privacy di questi siti web
                  prima di utilizzarli.
                </p>
                <h4>6. Ammende riguardo questa dichiarazione della privacy</h4>
                <p>
                  Ci riserviamo il diritto di apportare modifiche alla presente
                  dichiarazione sulla privacy. Si raccomanda di consultare
                  regolarmente questa dichiarazione sulla privacy per essere a
                  conoscenza di eventuali modifiche. Inoltre, ti informeremo
                  attivamente laddove possibile.
                </p>
                <h4>7. Accesso e modifica dei tuoi dati</h4>
                <p>
                  Se hai domande o vuoi sapere esattamente quali tuoi dati sono
                  in nostro possesso, contattaci. Potrai contattarci usando le
                  informazioni presenti qui sotto. Hai i seguenti diritti:
                </p>
                <ul type="square">
                    <li>
                      Hai il diritto di sapere quando i tuoi dati personali sono
                      necessari, cosa succede ad essi, quanto a lungo verranno
                      mantenuti.
                    </li>
                    <li>
                      Diritto di accesso: hai il diritto ad accedere ai tuoi
                      dati personali dei quali siamo a conoscenza.
                    </li>
                    <li>
                      Diritto di rettifica: hai il diritto di completare,
                      correggere, cancellare o bloccare i tuoi dati personali
                      quando lo desideri.
                    </li>
                    <li>
                      Se hai domande o problemi relativi al accessibilità del
                      sito web, per favore non esitare a contattarci.
                    </li>
                    <li>
                      Diritto di trasferire i tuoi dati: hai il diritto di
                      richiedere tutti i tuoi dati dal controllore e trasferirli
                      tutti quanti ad un altro controllore.
                    </li>
                    <li>
                      Diritto di obiezione: hai il diritto di obiezione verso il
                      processo dei tuoi dati. Noi rispetteremo questa scelta, a
                      meno che non ci siano delle basi valide per il processo.
                    </li>
                </ul>
                <p>Assicurati di segnalare sempre in modo chiaro chi sei, così da
                  essere certi di non cancellare o modificare i dati di un una
                  persona sbagliata.
                </p>
                <h4>8. Invio di un reclamo</h4>
                <p>
                  Se non sei soddisfatto di come gestiamo (una lamentela su) il
                  processo dei tuoi dati personali, hai il diritto di spedire un
                  reclamo al Garante per la protezione dei dati personali.
                </p>
                <h4>9. Dettagli contatti</h4>
                <p>
                  Il responsabile del trattamento è:<br />
                  Catia Lettieri<br />
                  Via Giuseppe Mazzini, 38 - 05021 Acquasparta TR Italia<br />
                  Sito web: https://animusoperandi.it<br />
                  E-mail: info@animusoperandi.it<br />
                  Numero di telefono: +393513772245
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Policies;