import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import HomePage from "./pages/Home";
import Termini from "./pages/TerminiECondizioni";
import Policies from "./pages/Policies";
import Cookie from "./pages/Cookie";
import "./index.css";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/termini-e-condizioni" element={<Termini />} />
          <Route path="/policies" element={<Policies />} />
          <Route path="/cookie" element={<Cookie />} />
          {/* ... altre rotte per le pagine */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
