import React from "react";
import "../index.css";
import ScrollToTopButton from "./ScrollToTopButton";

function Footer() {
  
  
  return (
    <footer id="footer" className="footer-section">
      <div className="container">
        <div className="footer-content pt-5 pb-5">
          <div className="row">
            <div className="col-xl-4 col-lg-4 mb-50">
              <div className="footer-widget">
                <div className="footer-logo">
                  <a href="/" className="logo d-flex align-items-center">
                    <img
                      src="/assets/images/logo-animus-operandi-chiaro.png"
                      alt="Animus Operandi"
                    />
                  </a>
                </div>
                {/* <div className="footer-text">
                  <p>
                    
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTopButton />
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 text-left text-lg-left">
              <div className="copyright-text">
                <p>
                  © 2024 Animus Operandi. Tutti i diritti riservati - Powered &
                  Designed by{" "}
                  <a
                    href="https://stefanocoggiola.com"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "orange" }}
                  >
                    Stefano Coggiola
                  </a>
                  .
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 d-none d-lg-block">
              <div className="footer-menu text-right copyright-text">
                <p>
                  Animus Operandi by{" "}
                  <a
                    href="https://www.catialettieri.it"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "orange" }}
                  >
                    Catia Lettieri
                  </a>{" "}
                  - Partita IVA: IT-01683150559
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
